import { render, staticRenderFns } from "./FaceBinding.vue?vue&type=template&id=68ea9d20&scoped=true"
import script from "./FaceBinding.vue?vue&type=script&lang=js"
export * from "./FaceBinding.vue?vue&type=script&lang=js"
import style0 from "./FaceBinding.vue?vue&type=style&index=0&id=68ea9d20&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.11.1_cache-loader@4.1.0_css-loader@3.6.0_lodash@4.17.21_prettier@2.8.8_vue-temp_pgqv4bjfw4n43uthtcnbh5tcie/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "68ea9d20",
  null
  
)

export default component.exports